import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {RecordEditStatus, Validation, ValidationLevel} from '../../../record-editing';
import {Button, TextControl, ValidationsList} from '../../../components/Form/FormStyles';
import {RemoveButtonX} from '../../../components/Buttons/RemoveButton';
import {EditedD365Account} from '../EditedPartner';
import {UserRoles} from '../../../providers/UserProvider';
import {isGuid} from '../../../utilities/guidUtilities';
import {Partner} from '../../../entities';

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0.25rem;
`;

const StyledAccountList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

const StyledAccountListItem = styled.li`
    margin-bottom: 0.5rem;
`;

const AccountContainer = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

const AddForm = styled.form`
    display: flex;
    gap .5rem;
    align-items: center;
`;

const NewAccountIdTextBox = styled(TextControl)`
    width: 360px;
`;

function validateNewAccount(newAccountId: string, accounts: EditedD365Account[]): Validation[] {
    const validations = [];

    if (newAccountId) {
        const newAccountIsADuplicate = accounts.find(
            (account) =>
                account.AccountId === newAccountId &&
                account.EditStatus !== RecordEditStatus.NewDeleted &&
                account.EditStatus !== RecordEditStatus.Deleted
        );
        if (newAccountIsADuplicate)
            validations.push({
                level: ValidationLevel.NotValidToSave,
                message: 'This is a duplicate'
            });

        const newAccountIsAGuid = isGuid(newAccountId);
        if (!newAccountIsAGuid)
            validations.push({
                level: ValidationLevel.NotValidToSave,
                message: 'The format is invalid for a D365 account id'
            });
    }

    return validations;
}

function D365AccountList({
    partnerId,
    accounts,
    addAccount,
    removeAccount
}: {
    partnerId: number;
    accounts: EditedD365Account[];
    addAccount: (account: EditedD365Account) => void;
    removeAccount: (account: EditedD365Account) => void;
}) {
    const [newAccountId, setNewAccountId] = useState('');
    const [usedByPartner, setUsedByPartner] = useState<Partner | null | 'pending'>(null);
    const roles = UserRoles();

    useEffect(() => {
        setNewAccountId('');
        setUsedByPartner(null);
    }, [partnerId]);

    async function handleNewAccountIdChanged(e) {
        setNewAccountId(e.target.value);
        if (isGuid(e.target.value)) {
            setUsedByPartner('pending');
            const partnersUsingAccountId = (
                (await (
                    await fetch(`/api/partners?d365AccountId=${e.target.value}`)
                ).json()) as Partner[]
            ).filter((p) => p.Id !== partnerId);
            const partnerUsingAccountId =
                partnersUsingAccountId.length === 0 ? null : partnersUsingAccountId[0];
            setUsedByPartner(partnerUsingAccountId);
        } else {
            setUsedByPartner(null);
        }
    }

    function handleAddAccountButtonClicked(e) {
        e.preventDefault();

        const account = {
            EditStatus: RecordEditStatus.New,
            AccountId: newAccountId
        };

        setNewAccountId('');
        addAccount(account);
    }

    function handleRemoveButtonClicked(account: EditedD365Account) {
        removeAccount(account);
    }

    const newAccountValidations = validateNewAccount(newAccountId, accounts);
    if (usedByPartner !== null && usedByPartner !== 'pending') {
        const alreadyUsedError = {
            level: ValidationLevel.NotValidToSave,
            message: `This account is already linked to ${
                usedByPartner.Archived ? 'archived ' : ''
            }partner ${usedByPartner.Name}`
        } as Validation;
        newAccountValidations.push(alreadyUsedError);
    }

    const nonDeletedAccounts = accounts.filter(
        (account) =>
            account.EditStatus !== RecordEditStatus.NewDeleted &&
            account.EditStatus !== RecordEditStatus.Deleted
    );

    return (
        <Layout>
            <StyledAccountList>
                {nonDeletedAccounts.map((account) => (
                    <StyledAccountListItem key={account.AccountId}>
                        <AccountContainer>
                            {roles.includes('editor') && (
                                <RemoveButtonX onClick={() => handleRemoveButtonClicked(account)} />
                            )}
                            <a
                                href={`https://arbordayfarm.crm.dynamics.com/main.aspx?appid=6dadc97e-4f53-46ad-b2e2-44a26f0ffd00&pagetype=entityrecord&etn=account&id=${account.AccountId}`}
                                target="_blank"
                                rel="noreferrer">
                                {account.AccountId}
                            </a>
                        </AccountContainer>
                    </StyledAccountListItem>
                ))}
            </StyledAccountList>
            <AddForm>
                <NewAccountIdTextBox value={newAccountId} onChange={handleNewAccountIdChanged} />
                {roles.includes('editor') && (
                    <Button
                        type="submit"
                        disabled={
                            !newAccountId ||
                            newAccountValidations.length > 0 ||
                            usedByPartner === 'pending'
                        }
                        onClick={handleAddAccountButtonClicked}>
                        Add account
                    </Button>
                )}
            </AddForm>
            <ValidationsList validations={newAccountValidations} />
        </Layout>
    );
}

export default D365AccountList;
