import styled from 'styled-components';
import {InfoAlert} from '../../../components/Alerts/Alerts';
import {Section, SectionHeader, SubSectionHeader} from '../../../components/Form/FormStyles';
import {EditedProject} from '../EditedProject';
import {CommunityDBHTreesDistributedControl} from '../MultipleProjectTypes/CommunityDBHTreesDistributedControl';
import {CommunityEventDatesControl} from '../MultipleProjectTypes/CommunityEventDatesControl';
import {CommunityTreesDistributedControl} from '../MultipleProjectTypes/CommunityTreesDistributedControl';
import {CommunityTreesPlantedControl} from '../MultipleProjectTypes/CommunityTreesPlantedControl';
import {CommunityTreesPlantedDBHControl} from '../MultipleProjectTypes/CommunityTreesPlantedDBHControl';
import {D365EventNumberControl} from './D365EventNumberControl';
import {FundingPartnersControl} from '../MultipleProjectTypes/FundingPartnersControl';
import {ImpactStoryCompletedControl} from '../MultipleProjectTypes/ImpactStoryCompletedControl';
import {ImpactStoryUneditedControl} from '../MultipleProjectTypes/ImpactStoryFromUnedited';
import {ImpactStoryInProgressControl} from '../MultipleProjectTypes/ImpactStoryInProgressControl';
import {LocationControl} from '../MultipleProjectTypes/LocationControl';
import {MapControl} from '../MultipleProjectTypes/Map/MapControl';
import {NameControl} from '../MultipleProjectTypes/NameControl';
import {NumberOfCommunityVolunteers} from './NumberOfCommunityVolunteers';
import {NumberOfCorporateVolunteersControl} from './NumberOfCorporateVolunteersControl';
import {PlantingPartnerControl} from '../MultipleProjectTypes/PlantingPartnerControl';
import {ProjectIdControl} from '../MultipleProjectTypes/ProjectIdControl';
import {ProjectStatusControl} from '../MultipleProjectTypes/ProjectStatusControl';
import {ProjectTypeControl} from '../MultipleProjectTypes/ProjectTypeControl';
import {ReasonForPlantingControl} from '../MultipleProjectTypes/ReasonForPlantingControl';
import {TreeSpeciesControl} from '../MultipleProjectTypes/TreeSpeciesControl';
import {ImagesSection} from '../MultipleProjectTypes/ImagesSection';
import {SiteSection} from './SiteSection';
import {D365EventCoordinationIdControl} from './D365EventCoordinationIdControl';

const TreeMetricsLayout = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 275px);
    gap: 1rem 1rem;
`;

export function CommunityProject({
    project,
    setProjectAndValidate,
    completedProjectSetProjectAndValidate,
    newProject,
    navigationPromptActive,
    setNavigationPromptActive,
    registerSave,
    registerHasChanges,
    hasChangesUpdated
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
    completedProjectSetProjectAndValidate: (project: EditedProject) => void;
    newProject?: boolean;
    navigationPromptActive: boolean;
    setNavigationPromptActive: (navigationPromptActive: boolean) => void;
    registerSave: (f: (projectId: number) => void) => void;
    registerHasChanges: (f: () => boolean) => void;
    hasChangesUpdated: () => void;
}) {
    return (
        <>
            <Section>
                {project.ControlledByAutomation && (
                    <InfoAlert style={{width: '100%', maxWidth: '1100px'}}>
                        This project is being automatically updated from D365. Most fields are
                        read-only on this page. To update them, go to the{' '}
                        <a
                            href={`https://arbordayfarm.crm.dynamics.com/main.aspx?appid=6dadc97e-4f53-46ad-b2e2-44a26f0ffd00&forceUCI=1&pagetype=entityrecord&etn=stn_eventcoordination&id=${project.D365_eventcoordinationid.current}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            D365 event coordination
                        </a>
                        .
                    </InfoAlert>
                )}
                <ProjectStatusControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                    completedProjectSetProjectAndValidate={completedProjectSetProjectAndValidate}
                />
            </Section>

            <Section>
                <SectionHeader>What is the project?</SectionHeader>
                <ProjectIdControl project={project} />
                <ProjectTypeControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                {newProject && (
                    <D365EventNumberControl
                        project={project}
                        setProjectAndValidate={setProjectAndValidate}
                    />
                )}
                <D365EventCoordinationIdControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <NameControl project={project} setProjectAndValidate={setProjectAndValidate} />
            </Section>

            <Section>
                <SectionHeader>Where is the project?</SectionHeader>
                <LocationControl project={project} setProjectAndValidate={setProjectAndValidate} />
                <SiteSection project={project} setProjectAndValidate={setProjectAndValidate} />
                <MapControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                    registerSave={registerSave}
                    registerHasChanges={registerHasChanges}
                    hasChangesUpdated={hasChangesUpdated}
                    navigationPromptActive={navigationPromptActive}
                    setNavigationPromptActive={setNavigationPromptActive}
                />
            </Section>

            <Section>
                <SectionHeader>When is the project?</SectionHeader>
                <CommunityEventDatesControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
            </Section>

            <Section>
                <SectionHeader>Why is the project being done?</SectionHeader>
                <ReasonForPlantingControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <ImpactStoryUneditedControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <ImpactStoryInProgressControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <ImpactStoryCompletedControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
            </Section>

            <Section>
                <SectionHeader>Who is participating in the project?</SectionHeader>
                <PlantingPartnerControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <SubSectionHeader>Funding partners</SubSectionHeader>
                <FundingPartnersControl participations={project.Participations} />
                <NumberOfCorporateVolunteersControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <NumberOfCommunityVolunteers
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
            </Section>

            <Section>
                <SectionHeader>What is being planted?</SectionHeader>
                <TreeMetricsLayout>
                    <CommunityTreesPlantedControl
                        project={project}
                        setProjectAndValidate={setProjectAndValidate}
                    />
                    <CommunityTreesDistributedControl
                        project={project}
                        setProjectAndValidate={setProjectAndValidate}
                    />
                    <CommunityTreesPlantedDBHControl
                        project={project}
                        setProjectAndValidate={setProjectAndValidate}
                    />
                    <CommunityDBHTreesDistributedControl
                        project={project}
                        setProjectAndValidate={setProjectAndValidate}
                    />
                </TreeMetricsLayout>
                <TreeSpeciesControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
            </Section>

            <ImagesSection
                newProject={newProject}
                project={project}
                setProjectAndValidate={setProjectAndValidate}
            />
        </>
    );
}
