import { useEffect, useRef } from 'react';
import { Config, useConfig } from '../../../../providers/ConfigProvider';
import styled from 'styled-components';
import Portal from '@arcgis/core/portal/Portal';
import PortalItem from '@arcgis/core/portal/PortalItem';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Basemap from '@arcgis/core/Basemap';
import TileLayer from '@arcgis/core/layers/TileLayer';
import Search from "@arcgis/core/widgets/Search.js";
import VectorTileLayer from '@arcgis/core/layers/VectorTileLayer';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import Home from "@arcgis/core/widgets/Home"
import Graphic from "@arcgis/core/Graphic";
import * as geometryEngineAsync from "@arcgis/core/geometry/geometryEngineAsync.js"
import Geometry from "@arcgis/core/geometry/Geometry"
import OAuthInfo from '@arcgis/core/identity/OAuthInfo';
import IdentityManager from '@arcgis/core/identity/IdentityManager';
import Editor from '@arcgis/core/widgets/Editor';
import ReferenceLayers from './reference-layers.json';
import {UserRoles} from '../../../../providers/UserProvider';

const MapLayout = styled.div`
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto;
    grid-template-areas:
        'header'
        'map';
    width: 100%;
    height: calc(100vh - 170px);
`;

const MapContainer = styled.div`
    grid-area: map;
`;

function getBasemap() {
    return new Basemap({
        baseLayers: [
            new TileLayer({
                url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer'
            }),
            new TileLayer({
                url: 'https://tiles.arcgis.com/tiles/nGt4QxSblgDfeJn9/arcgis/rest/services/Vibrant/MapServer',
                opacity: 0.25,
                maxScale: 250000
            }),
            new VectorTileLayer({
                style: ReferenceLayers
            })
        ]
    });
}

function getPolygonRenderer() {
    return {
        type: 'simple',
        symbol: {
            type: 'simple-fill',
            outline: {
                style: 'solid',
                cap: 'round',
                width: 2.0,
                color: '#F3C851'
            },
            color: [255, 255, 255, 0.15]
        }
    } as unknown as SimpleRenderer;
}

async function getPortal(config: Config) {
    const oaInfo = new OAuthInfo({
        appId: config.arcGISEnterpriseAppId,
        portalUrl: config.arcGISEnterpriseUrl
    });
    IdentityManager.registerOAuthInfos([oaInfo]);

    const portal = new Portal({
        url: config.arcGISEnterpriseUrl
    });
    portal.authMode = 'immediate';
    await portal.load();

    return portal;
}

function getMappedByIdProjectsLayer(config: Config, portal) {
    const mappedByIdProjectLayerId = config.mappedByIdProjectLayerId;

    const mappedByIdProjectItem = new PortalItem({
        id: mappedByIdProjectLayerId,
        portal: portal
    });

    const mappedByIdProjectsLayer = new FeatureLayer({
        portalItem: mappedByIdProjectItem,
        id: 'project-layer',
        title: 'Project'
    });

    return mappedByIdProjectsLayer;
}

async function getTempFeaturesForInitialization(mappedByIdProjectsLayer: FeatureLayer) {
    return (
        await mappedByIdProjectsLayer.queryFeatures({
            where: '',
            num: 1,
            outFields: ['*'],
            returnGeometry: true
        })
    ).features;
}

function getInitializationFeatures(
    mappedByIdProjectFeatures,
    tempFeaturesForInitialization
): {useTempFeaturesForInitialization: boolean; initializationFeatures; actualFeatures} {
    if (mappedByIdProjectFeatures.length > 0)
        return {
            useTempFeaturesForInitialization: false,
            initializationFeatures: mappedByIdProjectFeatures,
            actualFeatures: mappedByIdProjectFeatures
        };

    return {
        useTempFeaturesForInitialization: true,
        initializationFeatures: tempFeaturesForInitialization,
        actualFeatures: []
    };
}

async function createScratchpadLayer(
    mappedByIdProjectsLayer: FeatureLayer,
    projectId: number
): Promise<{scratchpadLayer: FeatureLayer; features}> {
    const graphic: Graphic[] = (
        await mappedByIdProjectsLayer.queryFeatures({
            where: `PlantingProjectID = '${projectId}'`,
            outFields: ['*'],
            returnGeometry: true
        })
    ).features;

    const tempFeaturesForInitialization = await getTempFeaturesForInitialization(
        mappedByIdProjectsLayer
    );

    const {useTempFeaturesForInitialization, initializationFeatures, actualFeatures} =
        getInitializationFeatures(graphic, tempFeaturesForInitialization);

    if (initializationFeatures.length === 0)
        console.error(
            `Couldn't create scratchpad layer because features couldn't be found for initialization`
        );

    const layer = new FeatureLayer({
        source: initializationFeatures.map((feature) => feature.clone()),
        objectIdField: 'OBJECTID',
        fields: [
            {
                name: 'OBJECTID',
                type: 'oid'
            }
        ],
        renderer: getPolygonRenderer(),
        popupEnabled: false,
        id: 'project-layer',
        title: 'Project'
    });

    if (useTempFeaturesForInitialization)
        await layer.applyEdits({deleteFeatures: initializationFeatures});

    layer.on('layerview-create', (e) => {
        // @ts-ignore
        e.layerView.layer.templates[0].name = 'Boundary';
    });

    return {scratchpadLayer: layer, features: actualFeatures};
}

function loadHome(view, feature) {
    if (!feature) return;

    if (feature.length > 1) return;

    const locateBtn = new Home({
        view: view,
        id: 'homeButton'
    });

    locateBtn.goToOverride = (view, goToParams) => {
        goToParams.options.duration = 3000;
        return view.goTo(feature, goToParams.options);
    };
    view.ui.add(locateBtn, {
        position: 'top-left'
    });
}

function loadSearch(view: MapView) {
    const searchWidget = new Search({
        view: view,
        suggestionsEnabled: true,
        locationEnabled: false
    });
    searchWidget.goToOverride = function (view, goToParams) {
        goToParams.options.duration = 3000;
        goToParams.options.animate = false;
        return view.goTo(goToParams.target, goToParams.options);
    };

    view.ui.add(searchWidget, {
        position: 'top-left',
        index: 0
    });
}
function loadEditor(view: MapView, layer: FeatureLayer) {
    view.map.layers.add(layer);

    const layerInfo = {
        layer: layer,
        addEnabled: true,
        deleteEnabled: true
    };

    const editor = new Editor({
        view: view,
        layerInfos: [layerInfo]
    });

    view.ui.add(editor, 'top-right');

    return editor;
}

const mergeScratchFeatures = async (scratchFeatures) => {
    let union: Geometry = scratchFeatures[0].geometry;

    for (let index = 1; index < scratchFeatures.length; index++) {
        union = await geometryEngineAsync.union([union, scratchFeatures[index].geometry]);
    }

    const graphic = new Graphic({
        geometry: union
    });

    return graphic;
};

function userCanEditFeatures(portal: Portal) {
    const privileges = portal.user.get('privileges') as string[];
    return privileges.includes('features:user:edit');
}

const loadMap = async (
    projectId: number,
    mapRef: any,
    config: Config,
    registerSave: (f: (projectId: number) => void) => void,
    registerHasChanges: (f: () => boolean) => void,
    hasChangesUpdated: () => void,
    canEdit: boolean
) => {
    const portal = await getPortal(config);
    const canEditFeatures = canEdit && userCanEditFeatures(portal);
    const mappedByIdProjectsLayer = getMappedByIdProjectsLayer(config, portal);

    const {scratchpadLayer, features} = await createScratchpadLayer(
        mappedByIdProjectsLayer,
        projectId
    );
    const basemap = getBasemap();

    const map = new Map({
        basemap: basemap,
        layers: [scratchpadLayer]
    });

    const view = new MapView({
        container: mapRef.current,
        map: map,
        zoom: 3,
        center: [0, 15]
    });

    let hasChanges = false;

    async function saveChanges(projectId: number) {
        if (!hasChanges || !canEditFeatures) return;

        const updatedFeatures: Graphic[] = [];
        const addedFeatures: Graphic[] = [];
        const deletedFeatures: Graphic[] = [];

        const scratchFeatures: Graphic[] = (await scratchpadLayer.queryFeatures()).features;

        //find the original feature - there should never be more than one
        const editFeatures = (
            await mappedByIdProjectsLayer.queryFeatures({
                where: `PlantingProjectID = ${projectId}`,
                outFields: ['*']
            })
        ).features;

        let scratchFeature: Graphic;

        if (scratchFeatures?.length > 1) {
            scratchFeature = await mergeScratchFeatures(scratchFeatures);
        }

        //if there was more than one feature they have been merged to one now
        scratchFeature =
            scratchFeatures?.length > 1
                ? await mergeScratchFeatures(scratchFeatures)
                : scratchFeatures[0];

        if (scratchFeature) {
            if (editFeatures?.length > 0) {
                const editFeature = editFeatures[0];
                editFeature.geometry = scratchFeature.geometry;
                editFeature.setAttribute('Notes', 'Updated using the editor tool');
                editFeature.setAttribute('GeometryValidated', 1);
                updatedFeatures.push(editFeature);
            } else {
                scratchFeature.setAttribute('PlantingProjectID', projectId);
                scratchFeature.setAttribute('Notes', 'Updated using the editor tool');
                scratchFeature.setAttribute('GeometryValidated', 1);
                addedFeatures.push(scratchFeature);
                loadHome(view, scratchFeature);
            }
        } else {
            //if no features, we need to delete
            const existingFeatures = (
                await mappedByIdProjectsLayer.queryFeatures({
                    where: `PlantingProjectID = ${projectId}`,
                    outFields: ['*']
                })
            ).features;
            for (const existingFeature of existingFeatures) {
                const matchingScratchFeatures = (
                    await scratchpadLayer.queryFeatures({
                        where: `OBJECTID = ${existingFeature.getObjectId()}`
                    })
                ).features;
                if (matchingScratchFeatures.length === 0) {
                    deletedFeatures.push(existingFeature);
                    view.ui.remove('homeButton');
                }
            }
        }

        await mappedByIdProjectsLayer.applyEdits({
            updateFeatures: updatedFeatures,
            addFeatures: addedFeatures,
            deleteFeatures: deletedFeatures
        });

        hasChanges = false;
        hasChangesUpdated();
    }

    view.when(async () => {
        view.on('mouse-wheel', function (e) {
            if (!e.native.ctrlKey) e.stopPropagation();
        });

        if (features.length > 0) {
            await view.whenLayerView(scratchpadLayer);
            view.goTo(features[0], {duration: 5000});
        }

        loadSearch(view);
        loadHome(view, features[0]);

        if (canEditFeatures) {
            const editor = loadEditor(view, scratchpadLayer);
            registerSave(saveChanges);
            registerHasChanges(() => hasChanges);

            scratchpadLayer.on('edits', (e) => {
                if (e.addedFeatures.length > 0) {
                    if (editor.activeWorkflow) editor.cancelWorkflow();
                    editor.layerInfos[0].addEnabled = true;
                }

                if (e.deletedFeatures.length > 0) {
                    if (editor.activeWorkflow) editor.cancelWorkflow();
                    editor.layerInfos[0].addEnabled = true;
                }

                hasChanges = true;
                hasChangesUpdated();
            });
        }
    });

    return () => {
        view && view.destroy();
        mappedByIdProjectsLayer.destroy();
        portal.destroy();
    };
};

export function ProjectMap({
    projectId,
    registerSave,
    registerHasChanges,
    hasChangesUpdated
}: {
    projectId: number;
    registerSave: (f: (projectId: number) => void) => void;
    registerHasChanges: (f: () => boolean) => void;
    hasChangesUpdated: () => void;
}) {
    const mapRef = useRef();
    const config = useConfig();
    const roles = UserRoles();
    const canEdit = roles.includes('editor');

    useEffect(() => {
        if (!config) return;
        const load = loadMap(
            projectId,
            mapRef,
            config,
            registerSave,
            registerHasChanges,
            hasChangesUpdated,
            canEdit
        );

        return () => {
            load.then((cleanup) => cleanup());
        };
    }, [projectId, mapRef, config]);

    return (
        <MapLayout>
            <MapContainer ref={mapRef} />
        </MapLayout>
    );
}
