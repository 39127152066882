import {Section, SectionHeader, SubSectionHeader} from '../../../components/Form/FormStyles';
import {EditedProject} from '../EditedProject';
import {AcresRestoredControl} from '../MultipleProjectTypes/AcresRestoredControl';
import {AgreementNumberControl} from './AgreementNumberControl';
import {CommitmentFiscalYearControl} from './CommitmentFiscalYearControl';
import {CommitmentsControl} from './CommitmentsControl';
import {ForestControl} from './ForestControl';
import {FundingPartnersControl} from '../MultipleProjectTypes/FundingPartnersControl';
import {ImpactStoryCompletedControl} from '../MultipleProjectTypes/ImpactStoryCompletedControl';
import {ImpactStoryInProgressControl} from '../MultipleProjectTypes/ImpactStoryInProgressControl';
import {LocationControl} from '../MultipleProjectTypes/LocationControl';
import { MapControl } from '../MultipleProjectTypes/Map/MapControl';
import {NameControl} from '../MultipleProjectTypes/NameControl';
import {PlantingPartnerControl} from '../MultipleProjectTypes/PlantingPartnerControl';
import {PlantingTimeframeControl} from './PlantingTimeframeControl';
import {ProjectIdControl} from '../MultipleProjectTypes/ProjectIdControl';
import {ProjectStatusControl} from '../MultipleProjectTypes/ProjectStatusControl';
import {ProjectTypeControl} from '../MultipleProjectTypes/ProjectTypeControl';
import {ProjectYearsControl} from '../MultipleProjectTypes/ProjectYearsControl';
import {ReasonForPlantingControl} from '../MultipleProjectTypes/ReasonForPlantingControl';
import {TotalTreeCostControl} from './TotalTreeCostControl';
import {TreeSpeciesControl} from '../MultipleProjectTypes/TreeSpeciesControl';
import {TTFDBHControl} from './TTFDBHControl';
import {TTFTreesPlantedControl} from './TTFTreesPlantedControl';
import {ImagesSection} from '../MultipleProjectTypes/ImagesSection';
import {ImpactStoryUneditedControl} from '../MultipleProjectTypes/ImpactStoryFromUnedited';
import {InfoAlert} from '../../../components/Alerts/Alerts';

export function ReforestationProject({
    project,
    setProjectAndValidate,
    completedProjectSetProjectAndValidate,
    newProject,
    navigationPromptActive,
    setNavigationPromptActive,
    registerSave,
    registerHasChanges,
    hasChangesUpdated
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
    completedProjectSetProjectAndValidate: (project: EditedProject) => void;
    newProject?: boolean;
    navigationPromptActive: boolean;
    setNavigationPromptActive: (navigationPromptActive: boolean) => void;
    registerSave: (f: (projectId: number) => void) => void;
    registerHasChanges: (f: () => boolean) => void;
    hasChangesUpdated: () => void;
}) {
    return (
        <>
            <Section>
                {project.ControlledByAutomation && (
                    <InfoAlert style={{width: '100%', maxWidth: '1100px'}}>
                        This project is being automatically updated from D365. Most fields are
                        read-only on this page. To update them, go to the{' '}
                        <a
                            href={`https://arbordayfarm.crm.dynamics.com/main.aspx?appid=c7eb68df-db27-494f-9033-5a185400ea7f&pagetype=entityrecord&etn=stn_plantingproject&id=${project.D365PlantingProjectId}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            D365 planting project
                        </a>
                        .
                    </InfoAlert>
                )}
                <ProjectStatusControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                    completedProjectSetProjectAndValidate={completedProjectSetProjectAndValidate}
                />
            </Section>

            <Section>
                <SectionHeader>What is the project?</SectionHeader>
                <ProjectIdControl project={project} />
                <ProjectTypeControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <NameControl project={project} setProjectAndValidate={setProjectAndValidate} />
            </Section>

            <Section>
                <SectionHeader>Where is the project?</SectionHeader>
                <LocationControl project={project} setProjectAndValidate={setProjectAndValidate} />
                <ForestControl project={project} setProjectAndValidate={setProjectAndValidate} />
                <MapControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                    registerSave={registerSave}
                    registerHasChanges={registerHasChanges}
                    hasChangesUpdated={hasChangesUpdated}
                    navigationPromptActive={navigationPromptActive}
                    setNavigationPromptActive={setNavigationPromptActive}
                />
            </Section>

            <Section>
                <SectionHeader>When is the project?</SectionHeader>
                <ProjectYearsControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <PlantingTimeframeControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
            </Section>

            <Section>
                <SectionHeader>Why is the project being done?</SectionHeader>
                <ReasonForPlantingControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <ImpactStoryUneditedControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                    disabled={project.ControlledByAutomation}
                />
                <ImpactStoryInProgressControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                    disabled={project.ControlledByAutomation}
                />
                <ImpactStoryCompletedControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                    disabled={project.ControlledByAutomation}
                />
            </Section>

            <Section>
                <SectionHeader>Who is participating in the project?</SectionHeader>
                <PlantingPartnerControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <CommitmentsControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <CommitmentFiscalYearControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <AgreementNumberControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <TotalTreeCostControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <SubSectionHeader>Funding partners</SubSectionHeader>
                <FundingPartnersControl participations={project.Participations} />
            </Section>

            <Section>
                <SectionHeader>What is being planted?</SectionHeader>
                <TTFTreesPlantedControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <TTFDBHControl project={project} setProjectAndValidate={setProjectAndValidate} />
                <AcresRestoredControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <TreeSpeciesControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
            </Section>

            <ImagesSection
                newProject={newProject}
                project={project}
                setProjectAndValidate={setProjectAndValidate}
            />
        </>
    );
}
