import { EditedParticipation } from "../../../Partner/EditedPartner";
import { StickyHeader } from "../../../../components/Table/TableStyles";
import { offsetOneRow, StateStyledCell } from "../../Styles/ParticipationTableStyles";
import { EmptyFilter } from "../Shared/EmptyFilter";

export function D365Header() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				textAlign: 'left',
				width: '60px',
				minWidth: '60px',
				maxWidth: '60px'
			}}>
			D365
		</StickyHeader>
	)
}

export function D365Filter() {
	return (
		<EmptyFilter />
	)
}

export function D365Content({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell editStatus={ppn.EditStatus}>
			{ppn.D365RevenuePostingInvoiceId !== null && (
				<a
					href={`https://arbordayfarm.crm.dynamics.com/main.aspx?appid=6dadc97e-4f53-46ad-b2e2-44a26f0ffd00&forceUCI=1&pagetype=entityrecord&etn=stn_revenuepostinginvoice&id=${ppn.D365RevenuePostingInvoiceId}`}
					target="_blank"
					rel="noopener noreferrer">
					View
				</a>
			)}
		</StateStyledCell>
	)
}